<template>
  <section>
    <div class="d-flex justify-space-between">
      <StatisticItem
        :value="totalAuditsReviewed"
        text="Total Audits Reviewed"
      />
      <StatisticItem
        :value="totalAuditsScore"
        is-percent
        text="Total Audits Score"
      />
      <StatisticItem
        :value="casesAmountReviewed"
        text="Total Cases Reviewed"
      />
    </div>
  </section>
</template>

<script>
import StatisticItem from "../common/StatisticItem.vue";
export default {
  name: "GeneralTabStatic",

  components: {
    StatisticItem,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    totalAuditsReviewed() {
      return this.data?.totalAuditsReviewed || 0;
    },
    totalAuditsScore() {
      return this.data?.totalAuditsScore || 0;
    },
    casesAmountReviewed() {
      return this.data?.caseAmountReviewed || 0;
    },
  },
};
</script>