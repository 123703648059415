<template>
  <section class="chart-tabs">
    <div class="tabs-container">
      <v-tabs
        v-model="tab"
        active-class="tabs-container__tab--active"
        center-active
        class="app-container"
        background-color="#F0F0F0"
      >
        <v-tab
          v-for="tabItem in tabsItems"
          :key="tabItem.title"
          :href="`#${tabItem.title}`"
          class="text-body-1 text-capitalize font-weight-bold pl-pr-2"
        >
          {{ tabItem.title }}
        </v-tab>
      </v-tabs>
    </div>

    <v-row class="app-container filters-container d-flex mb-6" cols="4">
      <v-col class="d-flex" cols="4">
        <v-select
          :value="filters.clinicalServiceId"
          :items="clinicalServices"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Services"
          item-text="name"
          item-value="_id"
          hide-details
          clearable
          outlined
          @change="handleFilterChange($event, 'clinicalServiceId')"
        />
      </v-col>
      <v-col class="d-flex" cols="4">
        <v-select
          :value="filters.auditTemplateId"
          :items="auditTemplates"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Audit Template"
          item-text="name"
          item-value="_id"
          hide-details
          clearable
          outlined
          @change="handleFilterChange($event, 'auditTemplateId')"
        />
      </v-col>
      <v-col class="d-flex" cols="4">
        <time-data-picker
          ref-name="auditEndDate"
          :date="filters.endDate"
          label="End Date"
          @change="handleFilterChange($event, 'endDate')"
        />
      </v-col>
      <v-col class="d-flex filters-buttons" cols="12">
        <ApplyButton large @click="fetchTabsData" />
        <CancelButton large @click="onFilterCancel" />
      </v-col>
    </v-row>

    <v-tabs-items class="app-container mb-6 mt-6" v-model="tab">
      <v-tab-item :value="tabKeys.cda.title" class="mb-3">
        <general-tab-static :data="generalReports?.[tabKeys.cda.value]" />

        <DocBatchAuditResult
          v-if="cdaEvaluationResult"
          :evaluation-result="cdaEvaluationResult"
          class="mt-15"
        />
      </v-tab-item>

      <v-tab-item :value="tabKeys.specialGeneral.title" class="mb-3">
        <general-tab-static
          :data="generalReports?.[tabKeys.specialGeneral.value]"
        />

        <DocBatchAuditResult
          v-if="generalEvaluationResult"
          :evaluation-result="generalEvaluationResult"
          class="mt-15"
        />
      </v-tab-item>

      <v-tab-item :value="tabKeys.specialCaseBased.title" class="mb-3">
        <general-tab-static
          :data="generalReports?.[tabKeys.specialCaseBased.value]"
        />
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import GeneralTabStatic from "./GeneralTabStatic.vue";
import TimeDataPicker from "@/components/controls/TimeDataPicker.vue";
import { getGeneralBatchReport } from "../../services/batch";
import { getAuditTemplates } from "@/services/auditTemplates";
import { getClinicalServices } from "@/services/clinicalServices";
import ApplyButton from "@/components/common/filterControls/ApplyButton.vue";
import CancelButton from "@/components/common/filterControls/CancelButton.vue";
import DocBatchAuditResult from "@/components/batch/DocBatchAuditResult.vue";

export default {
  name: "ReportingTabs",

  components: {
    GeneralTabStatic,
    TimeDataPicker,
    ApplyButton,
    CancelButton,
    DocBatchAuditResult,
  },

  data() {
    const tabKeys = {
      cda: {
        title: "Clinical Doc. Audits",
        value: "Clinical Doc.",
      },
      specialGeneral: {
        title: "Special General",
        value: "General Audit",
      },
      specialCaseBased: {
        title: "Special Case-Based",
        value: "Special Audit",
      },
    };

    const tabsItems = Object.values(tabKeys);

    return {
      tabKeys,
      tab: null,
      tabsItems,
      generalReports: null,
      auditTemplates: [],
      clinicalServices: [],
      generalEvaluationResult: null,
      cdaEvaluationResult: null,
      filters: {
        auditTemplateId: null,
        clinicalServiceId: null,
        endDate: null,
      },
    };
  },

  async mounted() {
    await Promise.all([
      this.fetchServices(),
      this.fetchTabsData(),
      this.fetchAuditTemplates(),
    ]);
  },

  methods: {
    async fetchTabsData() {
      try {
        const query = {
          filters: this.buildFilters(),
        };

        const reportsArr = await getGeneralBatchReport(query);
        this.generalReports = reportsArr.reduce((acc, item) => {
          acc[item._id] = item;
          return acc;
        }, {});

        this.generalEvaluationResult = this.generateEvaluationResult(this.generalReports[this.tabKeys.specialGeneral.value]);
        this.cdaEvaluationResult = this.generateEvaluationResult(this.generalReports[this.tabKeys.cda.value]);
      } catch (err) {
        this.$notify({
          type: "error",
          text: err?.message || JSON.stringify(err),
        });
      }
    },
    async fetchAuditTemplates() {
      try {
        const auditTemplatesData = await getAuditTemplates();
        this.auditTemplates = auditTemplatesData?.data;
      } catch (err) {
        this.$notify({
          type: "error",
          text: err?.message || JSON.stringify(err),
        });
      }
    },
    handleFilterChange(value, key) {
      this.filters[key] = value;
    },
    buildFilters() {
      const filters = {};
      if (Object.values(this.filters).some((val) => !!val === true)) {
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            if (key === "endDate" || key === "createdAt") {
              const [first, second] = this.filters[key];
              const startDate = new Date(first);
              const endDate = second
                ? new Date(second)
                : new Date(startDate.getTime() + 86400000);
              filters[key] = { $gte: startDate, $lt: endDate };
            } else {
              filters[key] = this.filters[key];
            }
          }
        });
      }
      return JSON.stringify(filters);
    },
    async fetchServices() {
      try {
        this.clinicalServices = await getClinicalServices();
      } catch (err) {
        this.$notify({
          type: "error",
          text: err?.message || JSON.stringify(err),
        });
      }
    },
    async onFilterCancel() {
      Object.keys(this.filters).forEach((key) => (this.filters[key] = null));
      await this.fetchTabsData();
    },
    generateEvaluationResult(statistic) {
      const less75Amount =
        statistic?.totalAuditsReviewed -
        statistic?.score90 -
        statistic?.score75;
      return [
        {
          name: "Audit Score ≥ 90%",
          amount: statistic?.score90 || 0,
          percent: ((statistic?.score90 || 0) * 100) / statistic?.totalAuditsReviewed || 0,
        },
        {
          name: "Audit Score ≥ 75%",
          amount: statistic?.score75 || 0,
          percent: ((statistic?.score75 || 0) * 100) / statistic?.totalAuditsReviewed || 0,
        },
        {
          name: "Audit Score < 75%",
          amount: less75Amount || 0,
          percent: ((less75Amount || 0) * 100) / statistic?.totalAuditsReviewed || 0,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.chart-tabs {
  .tabs-container {
    &__tab--active {
      color: var(--v-text-black-base);
    }

    .v-item-group {
      background-color: #f0f0f0 !important;
    }
  }

  .filters-container {
    padding: 4px;
  }

  .filters-buttons {
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .v-pagination {
    &__navigation {
      box-shadow: none;
    }

    &__item {
      box-shadow: none;

      &--active {
        background: #f0f0f0 !important;
        color: var(--v-text-black-base) !important;
      }
    }
  }
}
</style>
